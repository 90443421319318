<template>
  <div class="materials-info">
    <div class="page-content">
      <div class="item" v-for="(item, i) in materialList" :key="i">
        <van-cell
          is-link
          clickable
          :required="item.isNeed == '1'"
          :title="item.materialName"
          :value="`已上传${item.materialImages?.length || 0}张`"
          @click="choiceItem(item)"
        >
          <template #label>
            <div class="m-item-t">
              <span
                v-if="item.exampleUrl"
                class="custom-title clr-b"
                @click="previewDemo(item.exampleUrl)"
              >
                <van-icon name="eye-o" />
                示例
              </span>
              <span class="custom-title"
                >({{ acceptTipsFn(item.imageType) }})</span
              >
            </div>
          </template>
        </van-cell>
      </div>
    </div>

    <div class="page-btn btns">
      <van-button size="small" @click="$router.go(-1)">返回</van-button>

      <van-button
        v-if="isReturn"
        type="info"
        size="small"
        @click="editReturnStatus"
        >提交</van-button
      >
    </div>

    <MaterialsInfoUpload
      v-model="showDetailPicker"
      :itemdata="selectItem"
      @update="getMaterialsList"
    />
    <!-- 
      @update="setMaterialList" -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { materialsConfigList, changeReturnMaterialStatus } from "@/api/apply";
export default {
  components: {
    MaterialsInfoUpload: () => import("./MaterialsInfoUpload")
  },
  data() {
    return {
      materialList: [],
      bizNo: "",
      selectItem: {},
      isReturn: false,
      showDetailPicker: false,
      prePlatOrderNo: ""
    };
  },
  created() {
    const { isReturn } = this.$route.query;
    this.isReturn = !!isReturn;
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapGetters(["getUserOrderInfo"]),
    init() {
      const data = this.getUserOrderInfo();
      this.bizNo = data.bizNo || "";
      this.prePlatOrderNo = data.prePlatOrderNo || "";
      this.getMaterialsList();
    },
    acceptTipsFn(fileType) {
      if (fileType == "PIC") {
        return "请上传.jpg格式文件";
      } else if (fileType == "VIDEO") {
        return "请上传视频格式文件";
      } else if (fileType == "DOC") {
        return "请上传pdf格式文件";
      } else {
        return "image/*,video/*";
      }
    },
    async getMaterialsList() {
      try {
        const { data } = await materialsConfigList(this.bizNo);
        this.materialList = (data || []).map((e) => {
          e.lists = e.materialImages?.map((ee) => {
            return {
              ...ee,
              fileUrl: ee.imageUrl,
              fileName: ee.imageName
            };
          });
          return e;
        });
        if (this.isReturn)
          this.materialList = this.materialList.filter(
            (e) => e.isReturn == "Y"
          );
      } catch (error) {
        this.$errMsg(error);
      }
    },
    choiceItem(item) {
      this.selectItem = item;
      this.showDetailPicker = true;
    },
    async editReturnStatus() {
      try {
        const unpass = this.materialList.some((e) => !e.materialImages.length);
        if (unpass) throw "请重新上传所有被退回的材料项";
        this.$toast.loading({
          forbidClick: true,
          duration: 0
        });
        await changeReturnMaterialStatus(this.bizNo);
        this.$toast.success("提交成功");
        this.$router.push("/apply-detail");
      } catch (error) {
        this.$errMsg(error);
      }
    }
    // async setMaterialList() {
    //   const item = this.materialList.find(e => e.materialCode == this.materialCode)
    //   const images = item?.materialImages
    //   const materialList = [...this.materialConfig].map((item) => ({
    //     ...item,
    //     bizNo: this.bizNo,
    //     lists: (images || []).map((item2) => ({
    //       ...item2,
    //       fileUrl: item2.imageUrl,
    //       fileName: item2.imageName
    //     }))
    //   }));
    //   this.materialList = [...materialList];
    // },
  }
};
</script>
<style lang="scss" scoped>
.materials-info {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  .page-content {
    flex: 1;
    overflow-y: auto;
    padding: 0 24px;
    padding-bottom: 24px;
  }

  .page-btn {
    height: 112px;
    min-height: 112px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-top: 1px solid #eee;
    box-shadow: 0 -10px 10px #f6f6f6;
    z-index: 10;
    .van-button {
      width: 45%;
      height: 100%;
    }
  }
}
</style>
